import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EUICustomizable = _resolveComponent("EUICustomizable")
  const _component_EUIPropAliases = _resolveComponent("EUIPropAliases")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_EUICustomizable),
    _createVNode(_component_EUIPropAliases)
  ], 64))
}